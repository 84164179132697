font {
  font-family: 'Montserrat', sans-serif;
}

.p {
  font-size: 100pt;
}
*,::after,::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth
}

@media only screen and (max-width: 75em) {
  html {
    font-size:59%
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size:56%
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size:65%
  }
}

body {
  box-sizing: border-box;
  position: relative;
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: inherit
}

li {
  list-style: none
}

a:focus,button:focus,input:focus,textarea:focus {
  outline: 0
}

button {
  border: none;
  cursor: pointer
}

textarea {
  resize: none
}

.heading_primary {
  font-size: 6rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  color: #111;
  font-weight: 800
}

@media only screen and (max-width: 37.5em) {
  .heading_primary {
    font-size:4.5rem
  }
}

.heading_sec_mb_bg {
  margin-bottom: 11rem
}

@media only screen and (max-width: 56.25em) {
  .heading_sec_mb_bg {
    margin-bottom:8rem
  }
}

.heading_sec_mb_med {
  margin-bottom: 9rem
}

@media only screen and (max-width: 56.25em) {
  .heading_sec_mb_med {
    margin-bottom:8rem
  }
}

.heading_sec_main {
  display: block;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 3.5rem;
  position: relative;
  color: #111
}

.heading_sec_main_lt {
  color: #111;
  font-weight: 800
}

.heading_sec_main_lt::after {
  content: '';
  background: #5ABECB!important
}

.heading_sec_main::after {
  content: '';
  position: absolute;
  top: calc(100% + 1.5rem);
  height: 5px;
  width: 3rem;
  background: #5ABECB;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px
}

@media only screen and (max-width: 37.5em) {
  .heading_sec_main::after {
    top:calc(100% + 1.2rem)
  }
}

.heading_sec_sub {
  display: block;
  text-align: center;
  color: #555;
  font-size: 2rem;
  font-weight: 500;
  max-width: 80rem;
  margin: auto;
  line-height: 1.6
}

@media only screen and (max-width: 37.5em) {
  .heading_sec_sub {
    font-size:1.8rem
  }
}

.heading_sec_sub_lt {
  color: #333
}

.heading_sm {
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 1px
}

.main_container {
  max-width: 120rem;
  margin: auto;
  width: 92%
}

.btn {
  background: #5ABECB;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
  transition: transform .3s
}

.btn:hover {
  transform: translateY(-3px)
}

.btn_bg {
  padding: 1.5rem 8rem;
  font-size: 2rem
}

.btn_med {
  padding: 1.5rem 5rem;
  font-size: 1.6rem
}

.btn_theme {
  background: #5ABECB;
  color: #111
}

.btn_theme_inv {
  color: #111;
  background: #fff;
  border: 2px solid #5ABECB;
  box-shadow: none;
  padding: calc(1.5rem - 2px) calc(5rem - 2px)
}

.sec_pad {
  padding: 12rem 0
}

@media only screen and (max-width: 56.25em) {
  .sec_pad {
    padding:8rem 0
  }
}

.text_primary {
  color: #555555 !important;
  font-size: 2.2rem;
  text-align: center;
  width: 100%;
  line-height: 1.6
}

@media only screen and (max-width: 37.5em) {
  .text_primary {
    font-size:2rem
  }
}

.d-none {
  display: none
}

.mouse {
  width: 25px;
  height: 40px;
  border: 2px solid #333;
  border-radius: 60px;
  position: relative;
  overflow: hidden
}

.mouse::before {
  content: '';
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 1.3s infinite;
  -webkit-animation: wheel 1.3s infinite
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 27px
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 27px
  }
}
