
.projects_row {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 5rem;
    margin-bottom: 11rem
}

@media only screen and (max-width: 56.25em) {
    .projects_row {
        grid-template-columns:1fr;
        grid-gap: 2rem;
        margin-bottom: 8rem
    }
}

@media only screen and (max-width: 56.25em) {
    .projects_row {
        text-align:center
    }
}

.projects_row:last-child {
    margin-bottom: 0
}

.projects_row_img_cont {
    overflow: hidden
}

.projects_row_img {
    width: 50%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover
}

.projects_row_content {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start
}

@media only screen and (max-width: 56.25em) {
    .projects_row_content {
        align-items:center
    }
}

.projects_row_content_title {
    font-weight: 700;
    font-size: 2.8rem;
    margin-bottom: 2rem
}

@media only screen and (max-width: 37.5em) {
    .projects_row_content_title {
        font-size:2.4rem
    }
}

.projects_row_content_desc {
    font-size: 1.8rem;
    color: #666;
    max-width: 60rem;
    line-height: 1.7;
    margin-bottom: 3rem
}

@media only screen and (max-width: 37.5em) {
    .projects_row_content_desc {
        font-size:1.7rem
    }
}
