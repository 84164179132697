.home_hero {
    color: #fff;
    background: linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url(../../assets/img/common-bg.svg);
    background-position: center;
    height: 100vh;
    min-height: 80rem;
    max-height: 120rem;
    position: relative
}

@media only screen and (max-width: 37.5em) {
    .home_hero {
        height:unset;
        min-height: unset
    }
}

.home_hero_socials {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    box-shadow: rgba(100,100,111,.2) 0 7px 29px 0;
    padding: .5rem
}

@media only screen and (max-width: 56.25em) {
    .home_hero_socials {
        display:none
    }
}

.home_hero_mouse_scroll_cont {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%)
}

@media only screen and (max-width: 37.5em) {
    .home_hero_mouse_scroll_cont {
        display:none
    }
}

.home_hero_social {
    width: 5rem
}

.home_hero_social_icon_link {
    width: 100%;
    display: block;
    padding: 1rem;
    transition: background .3s;
    border-radius: 5px
}

.home_hero_social_icon_link:hover {
    background: rgba(245,245,245,.9)
}

.home_hero_social_icon {
    width: 100%
}

.home_hero_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90rem;
    width: 92%
}

@media only screen and (max-width: 37.5em) {
    .home_hero_content {
        padding:19rem 0 13rem 0;
        margin: auto;
        position: static;
        transform: translate(0,0)
    }
}

.home_hero_info {
    margin: 3rem auto 0 auto;
    max-width: 80rem
}

.home_social_cta {
    margin-top: 10rem;
    margin-bottom: -10rem;
   text-align: center;

}


