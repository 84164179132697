.about {
    background: #F2EFEA
}

.about_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10rem
}

@media only screen and (max-width: 56.25em) {
    .about_content {
        grid-template-columns:1fr;
        grid-gap: 8rem
    }
}

.about_content_title {
    font-weight: 700;
    font-size: 2.8rem;
    margin-bottom: 3rem
}

@media only screen and (max-width: 37.5em) {
    .about_content_title {
        font-size:2.4rem
    }
}

.about_content_details_para {
    font-size: 1.8rem;
    color: #666;
    max-width: 60rem;
    line-height: 1.7;
    margin-bottom: 1rem
}


.about_content_details_para:last-child {
    margin-bottom: 4rem
}

.about_content_details_para a {
    text-decoration: underline;
    font-weight: 700;
    color: #5ABECB;
    margin: 0 3px
}

.skills {
    display: flex;
    flex-wrap: wrap;
}

.skills_skill {
    padding: 1rem 2rem;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    font-size: 1.6rem;
    /*background: rgba(153,153,153,.2);*/
    border-radius: 5px;
    font-weight: 600;
    color: #666
}

.img_skill {
    width: 50px;
    height: 50px;
}
