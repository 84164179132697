.contact_icon {
    padding: 10px;
    width: 100px;
    margin: auto;
    display: block;

}

.contact {
    background: #F2EFEA;
    background-size: cover;
    background-position: center
}


@media only screen and (max-width: 37.5em) {
}

@media only screen and (max-width: 37.5em) {
}
