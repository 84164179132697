
.main_footer {
    background: #000;
    color: #fff
}

.main_footer_upper {
    display: flex;
    justify-content: space-between;
    padding: 8rem 0
}

@media only screen and (max-width: 56.25em) {
    .main_footer_upper {
        padding:6rem 0
    }
}

@media only screen and (max-width: 37.5em) {
    .main_footer_upper {
        display:block
    }
}

.main_footer_row_1 {
    order: 2;

}

@media only screen and (max-width: 56.25em) {
    .main_footer_row_1 {
        margin-bottom:5rem
    }
}

.main_footer_row_2 {
    width: 40%;
    order: 1;
    max-width: 50rem;
}

@media only screen and (max-width: 56.25em) {
    .main_footer_row_2 {
        width:100%
    }
}

.main_footer_short_desc {
    margin-top: 2rem;
    color: #eee;
    font-size: 1.5rem;
    line-height: 1.7
}

.main_footer_social_cont {
    margin-top: 2rem
}

.main_footer_icon {
    margin-right: 1rem;
    width: 2.5rem
}

.main_footer_lower {
    padding: 4rem 0;
    border-top: 1px solid #444;
    color: #eee;
    font-size: 1.2rem;
    text-align: center
}

.main_footer_lower a {
    text-decoration: underline;
    font-weight: 700;
    margin-left: 2px
}

@media only screen and (max-width: 56.25em) {
    .main_footer_lower {
        padding:3.5rem 0
    }
}

