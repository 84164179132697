.header {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background: #fff;
    box-shadow: 0 10px 100px rgba(0,0,0,.1)
}

.header_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem
}

@media only screen and (max-width: 56.25em) {
    .header_content {
        padding:0 2rem
    }
}

.header_logo_container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #333;
    transition: color .3s
}

.header_logo_container:hover {
    color: #5ABECB
}

.header_logo_img_cont {
    width: 5rem;
    height: 5rem;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 1.5rem;
    background: #5ABECB
}

@media only screen and (max-width: 56.25em) {
    .header_logo_img_cont {
        width:4.5rem;
        height: 4.5rem;
        margin-right: 1.2rem
    }
}

.header_logo_img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    display: block
}

.header_logo_sub {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px
}

.header_links {
    display: flex
}

@media only screen and (max-width: 37.5em) {
    .header_links {
        display:none
    }
}

.header_link {
    padding: 2.2rem 3rem;
    display: inline-block;
    font-size: 1.6rem;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    transition: color .3s
}

.header_link:hover {
    color: #5ABECB;
}

@media only screen and (max-width: 56.25em) {
    .header_link {
        padding:3rem 1.8rem;
        font-size: 1.5rem
    }
}

.header_main_ham_menu_cont {
    display: none;
    width: 3rem;
    padding: 2.2rem 0
}

@media only screen and (max-width: 37.5em) {
    .header_main_ham_menu_cont {
        display:block
    }
}

.header_main_ham_menu {
    width: 100%
}

.header_main_ham_menu_close {
    width: 100%
}

.header_sm_menu {
    background: #fff;
    position: absolute;
    width: 100%;
    top: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.1);
    -webkit-box-shadow: 0 5px 5px 0 rgba(0,0,0,.1);
    -moz-box-shadow: 0 5px 5px 0 rgba(0,0,0,.1)
}

@media only screen and (max-width: 37.5em) {
}

.header_sm_menu_link a {
    display: block;
    text-decoration: none;
    padding: 2.5rem 3rem;
    font-size: 1.6rem;
    color: #333;
    text-align: right;
    border-bottom: 1px solid #eee;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: color .3s
}

.header_sm_menu_link a:hover {
    color: #5ABECB
}

.header_sm_menu_link:first-child a {
    border-top: 1px solid #eee
}

